<template>
    <v-container fluid>
        <v-form
            ref="form"
            v-model="validForm"
            lazy-validation
        >
            <v-row>
                <v-col cols="12"
                    lg="12"
                    md="12"
                    sm="12"
                >
                    <h3>{{ $t('keyAccount') }}</h3>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <hr />
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12"
                    lg="3"
                    md="12"
                    sm="12"
                >
                    <v-switch 
                        v-model="keyAccountRequest.status"
                        :label="$t('ativar')"
                        color="var(--color__main)"
                        :value=1
                    >
                    </v-switch>
                </v-col>
            </v-row>
            <v-row style="height: 100px">
                <v-col 
                    cols="12"
                    lg="6"
                    md="4"
                    sm="12"
                >
                    <label>{{ $t('cliente') }}</label>
                    <v-combobox
                        v-model="keyAccountRequest.clienteSelected"
                        :items="listClientes"
                        item-text="nameAndCnpj"
                        item-value="cnpjProcessed"
                        :rules=[validations.required]
                        clearable
                        single-line
                        outlined
                        :loading="loadingClientes"
                    ></v-combobox>
                </v-col>
                <v-col 
                    cols="12"
                    lg="3"
                    md="4"
                    sm="12"
                >
                    <label>{{ $t('tipoCliente') }}</label>
                    <v-combobox
                        v-model="keyAccountRequest.tipoClienteSelected"
                        :items="listTipoCliente"
                        :rules=[validations.required]
                        item-text="descricao"
                        item-value="id"
                        clearable
                        outlined
                    ></v-combobox>
                </v-col>
                <v-col 
                    style="display: flex; align-content: center; align-items: center;"
                    cols="12"
                    lg="3"
                    md="4"
                    sm="12"
                >
                    <v-btn
                        class="mx-2"
                        outlined
                        @click="showDialogImportacao = true;"
                    >
                        {{ $t('importarKeyAccount') }}
                    </v-btn>
                </v-col>
            </v-row>

            <v-row>
                <v-col 
                    cols="12"
                    lg="12"
                    md="12"
                    sm="12"
                >
                    <ContentHeader :description="$t('produto')" />
                </v-col>
            </v-row>

            <v-row style="height: 25px;">
                <v-col 
                    cols="12"
                    lg="5"
                    md="5"
                    sm="12"
                >
                    <label>{{ $t('codigoProduto') }}</label>
                </v-col>
                <v-col 
                    cols="12"
                    lg="2"
                    md="2"
                    sm="12"
                >
                    <label>{{ $t('codigoCliente') }}</label>
                </v-col>
                <v-col 
                    cols="12"
                    lg="2"
                    md="2"
                    sm="12"
                >
                    <label>{{ $t('moeda') }}</label>
                </v-col>
                <v-col 
                    cols="12"
                    lg="2"
                    md="2"
                    sm="12"
                >
                    <label>{{ $t('precoLiquido') }}</label>
                </v-col>
            </v-row>
            <v-row v-for="(item, index) in keyAccountRequest.listItemsKeyAccount" :key="index" style="height: 80px;" >
                <v-col 
                    cols="12"
                    lg="5"
                    md="5"
                    sm="12"
                >
                    <v-text-field
                        v-model="item.codigoProduto" 
                        :rules=[validations.required]
                        single-line
                        outlined
                    >
                    </v-text-field>
                </v-col>
                <v-col 
                    cols="12"
                    lg="2"
                    md="2"
                    sm="12"
                >
                    <v-text-field
                        v-model="item.codigoProdutoCliente" 
                        :rules=[validations.required]
                        single-line
                        outlined
                    >
                    </v-text-field>
                </v-col>
                <v-col 
                    cols="12"
                    lg="2"
                    md="2"
                    sm="12"
                >
                    <v-combobox
                        v-model="item.cambioSelected"
                        :items="listCambio"
                        item-text="codigoMoeda"
                        item-value="id"
                        :rules=[validations.required]
                        clearable
                        single-line
                        outlined
                    ></v-combobox>
                </v-col>
                <v-col 
                    cols="12"
                    lg="2"
                    md="2"
                    sm="12"
                >
                    <v-text-field
                        v-model="item.preco"
                        :prefix="item.cambioSelected != null && item.cambioSelected != undefined ? item.cambioSelected.simbolo : ''"
                        v-formatMoney="moneyFormat"
                        :rules=[validations.required]
                        outlined
                    >
                    </v-text-field>
                </v-col>
                <v-col 
                    cols="1" 
                    :class="item.showAddNewItem != true ? 'colButtonList' : 'colButtonList2'"
                >
                    <v-tooltip top v-if="item.showAddNewItem == true" style="padding-bottom: 5px;">
                        <template v-slot:activator="{ on }">
                            <v-btn 
                                class="btnActions"
                                outlined
                                fab
                                x-small
                                v-on="on"
                                @click="addNewItem(item)" 
                                color="blue" 
                                small
                                :key="index"
                            >
                                <v-icon>mdi-plus</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t('adicionarNovoItem') }}</span>
                    </v-tooltip>
                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-btn 
                                class="btnActions"
                                outlined
                                fab
                                x-small
                                v-on="on"
                                @click="deleteItem(index)" 
                                color="red" 
                                small
                                :disabled="keyAccountRequest.listItemsKeyAccount.length <= 1"
                            >
                                <v-icon>mdi-delete</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t('excluirItem') }}</span>
                    </v-tooltip>
                </v-col>
            </v-row>
            
            <br />

            <ActionButtons
                :request="keyAccountRequest"
                :loading="loading"
                :showButtons="{
                    cancel: true,
                    save: true
                }"
                @cancel="cancel"
                @save="save"
            />
        </v-form>

		<v-dialog
			v-model="showDialogImportacao"
			transition="dialog-top-transition"
			persistent
			width="500"
			:fullscreen="$vuetify.breakpoint.mobile"
		>
		
			<v-card>
				<v-card-title class="text-h5 lighten-2" style="border-bottom: solid 1px var(--color__cinza)">
					{{ $t('importarKeyAccount') }}
				</v-card-title>

				<v-card-text style="margin-top: 20px;">
					<v-row>
						<v-col 
                            cols="12"
							lg="12"
							md="12"
							sm="12"
						>
                            <v-switch 
                                v-model="sobreEscreverProdutos"
                                label="Sobrescrever Produtos Existentes?"
                                color="var(--color__main)"
                            >
                            </v-switch>
						</v-col>
					</v-row>
					<v-row>
						<v-col 
                            cols="12"
							lg="12"
							md="12"
							sm="12"
						>
							<label>{{ $t('keyAccount') }}</label>
                            <v-combobox
                                v-model="keyAccountSelected"
                                :items="listKeyAccount"
                                item-text="nomeCliente"
                                item-value="id"
                                clearable
                                outlined
                            ></v-combobox>
						</v-col>
					</v-row>
				</v-card-text>

				<v-divider></v-divider>

				<v-card-actions>
					<div style="width: 100%; text-align: center;">
						<v-btn
							class="mx-2 dialogButtonCancel"
							outlined
							@click="showDialogImportacao = false"
						>
							{{ $t('cancelar') }}
						</v-btn>

						<v-btn
							class="mx-2"
							outlined
							@click="confirmarImportacao()"
						>
							{{ $t('confirmar') }}
						</v-btn>
					</div>
				</v-card-actions>
			</v-card>
		</v-dialog>

        <ActionDialog 
            v-on:methodConfirmToCall="dialog.methodConfirm"
            :showDialog.sync="dialog.show"
            :headerTitle="dialog.headerText"
            :bodyText="dialog.bodyText"
            :params="dialog.params"
        />

    </v-container>
</template>

<script>
    import ContentHeader from "@/components/Layout/ContentHeader.vue";
    import { required } from "@/utilities/Rules";
    import Helpers from '@/utilities/Helpers';
    import ActionButtons from "@/components/Layout/ActionButtons";
    import ActionDialog from "@/components/Layout/ActionDialog";
    import { LIST_TIPO_CLIENTE } from "@/utilities/Enums";

    export default ({

        mixins: [Helpers],

        components: {
            ContentHeader,
            ActionButtons,
            ActionDialog
        },

        data: () => ({

            validForm: true,

            keyAccountRequest: {
                id: 0,
                status: 1,
                clienteSelected: null,
                tipoClienteSelected: null,
                listItemsKeyAccount: []
            },

            listClientes: [],
            loadingClientes: false,
            listCambio: [],
            listTipoCliente: LIST_TIPO_CLIENTE,

            moneyFormat: {
                suffix: '',
                thousands: '.',
                decimal: ',',
                precision: 2,
            },

            showDialogImportacao: false,
            sobreEscreverProdutos: false,
            keyAccountSelected: null,
            listKeyAccount: [],

            dialog: {
                show: false,
                headerText: "",
                bodyText: "",
                methodConfirm: ( () => {} ),
                params: null
            },

            loading: false,
            
            validations: {
                required: required,
            }
        }),

        props: {
            id: {
                default: 0
            },

            clonar: {
                default: false
            },
        },

        methods: {
            
            async deleteItem(index) {
                this.keyAccountRequest.listItemsKeyAccount.splice(index, 1);
                
                let lastIndex = this.keyAccountRequest.listItemsKeyAccount.length - 1;

                this.keyAccountRequest.listItemsKeyAccount[lastIndex].showAddNewItem = true;
            },

            async addNewItem(itemAtual) {

                if (itemAtual !== null && itemAtual !== undefined) {
                    itemAtual.showAddNewItem = false;
                }

                this.keyAccountRequest.listItemsKeyAccount.push({
                    cambioSelected: null,
                    showAddNewItem: true
                })
            },
            
            async getRegister() {

                if (this.id !== 0) {

                    const response = await this.$store.dispatch("keyAccountModule/GetById", this.id);

                    if (response.success) {
                        this.keyAccountRequest = response.result;
                        
                        this.keyAccountRequest.clienteSelected = {
                            id: this.keyAccountRequest.idCliente,
                            nameAndCnpj: this.keyAccountRequest.nameAndCnpj
                        }

                        if (this.keyAccountRequest.listItemsKeyAccount.length == 0) {
                            this.addNewItem();
                        }
                        else {
                            this.keyAccountRequest.listItemsKeyAccount.forEach(item => {
                                item.preco = item.precoFormatted;
                            })
                        }

                        this.keyAccountRequest.tipoClienteSelected = {
                            id: this.keyAccountRequest.tipoCliente,
                            descricao: this.keyAccountRequest.tipoClienteDescricao
                        }
                    }

                }
                else {
                    this.keyAccountRequest.status = 1;
                    this.addNewItem();
                }
            },

            async getLists() {
                
                this.listClientes = await this.$store.dispatch("empresaModule/ListClientes");
                this.listCambio = await this.$store.dispatch("cambioModule/List");
                this.listKeyAccount = await this.$store.dispatch("keyAccountModule/List");
            },

            async cancel() {
                this.$router.push({ path: "/keyAccount/keyAccountList" });
            },

            async validateKeyAccount() {

                let valid = true;

                if (this.keyAccountRequest.listItemsKeyAccount !== null && this.keyAccountRequest.listItemsKeyAccount !== undefined) {

                    let listItens = [...this.keyAccountRequest.listItemsKeyAccount];
                    let newListMenu = [];

                    this.keyAccountRequest.listItemsKeyAccount.forEach(item => {

                        item.codigoProduto = item.codigoProduto.trim();
                        
                        let existItem = listItens.filter(itm => itm.codigoProduto === item.codigoProduto);

                        if (existItem.length > 1 && valid) {
                            this.showToast("warning", this.$t('aviso'), `${this.$t('produto')} [${item.codigoProduto}] ${this.$t('jaFoiAdicionado')}`);
                            valid = false;
                        }
                        else {

                            newListMenu.push(item);
                        }
                    });

                    if (valid) {
                        this.keyAccountRequest.listItemsKeyAccount = newListMenu;
                    }
                }

                return valid;
            },

            confirmarImportacao(id) {

                let nomeKeyAccount = '';

                if (this.keyAccountSelected != null && this.keyAccountSelected != undefined) {
                    nomeKeyAccount = this.keyAccountSelected.nomeCliente;
                }

                let texto = ` ${this.$t('perguntaImportacaoKeyAccount')} "${nomeKeyAccount}"`;

                if (this.sobreEscreverProdutos == true) {
                    texto += `, ${this.$t('perguntaComplementoImportacaoKeyAccount')}`;
                }

                texto += `. ${this.$t('confirmaDecisao')}?`

                this.dialog = {
                    show: true,
                    headerText: this.$t('confirmacao'),
                    bodyText: texto,
                    methodConfirm: this.importar,
                    params: id
                };
            },

            async addProduto(itemKeyAccount) {

                if (itemKeyAccount.codigoProduto != null && itemKeyAccount.codigoProduto.toString().trim() != '') {
                    let item = {
                        id: 0,
                        codigoProduto: itemKeyAccount.codigoProduto,
                        codigoProdutoCliente: itemKeyAccount.codigoProdutoCliente,
                        cambioSelected: itemKeyAccount.cambioSelected,
                        preco: itemKeyAccount.precoFormatted,
                        showAddNewItem: false,
                    };

                    this.keyAccountRequest.listItemsKeyAccount.push(item);
                }
            },

            async importar() {

                this.showLoading();

                let idKeyAccount = 0;

                if (this.keyAccountSelected != null && this.keyAccountSelected != undefined) {
                    idKeyAccount = this.keyAccountSelected.id;
                }

                const response = await this.$store.dispatch("keyAccountModule/GetById", idKeyAccount);

                if (response.success) {

                    const keyAccount = response.result;

                    keyAccount.listItemsKeyAccount.forEach(item => {

                        if (this.sobreEscreverProdutos == true) {
                            let keyAccountItemExistente = this.keyAccountRequest.listItemsKeyAccount.filter(kac => kac.codigoProduto == item.codigoProduto)[0];

                            if (keyAccountItemExistente != null && keyAccountItemExistente != undefined) {
                                keyAccountItemExistente.codigoProdutoCliente = item.codigoProdutoCliente;
                                keyAccountItemExistente.cambioSelected = item.cambioSelected;
                                keyAccountItemExistente.preco = item.precoFormatted;
                            }
                            else {
                                this.addProduto(item);
                            }
                        }
                        else {
                            this.addProduto(item);
                        }
                    });  
                    
                    this.keyAccountRequest.listItemsKeyAccount.forEach(item => {
                        item.showAddNewItem = false;
                    });

                    this.keyAccountRequest.listItemsKeyAccount[this.keyAccountRequest.listItemsKeyAccount.length - 1].showAddNewItem = true;
                }

                this.hideLoading();
                this.showDialogImportacao = false;
            },

            async save() {

                await this.$refs.form.validate();

                if (this.validForm === false) {
                    this.showToast("error", this.$t('aviso'), this.$t('inconsistenciaFormulario'));
                    return false;
                }
                else {

                    if (await this.validateKeyAccount()) {
                        this.loading = true;
                        this.showLoading();

                        if (this.keyAccountRequest.tipoClienteSelected != null && this.keyAccountRequest.tipoClienteSelected != undefined) {
                            this.keyAccountRequest.idTipoCliente = this.keyAccountRequest.tipoClienteSelected.id;
                        }

                        this.keyAccountRequest.id = this.id;
                    
                        if (this.clonar == true) {
                            this.keyAccountRequest.id = 0;
                        }

                        const result = await this.$store.dispatch("keyAccountModule/CreateUpdate", this.keyAccountRequest);

                        if (result.success === true) {
                            this.showToast("success", this.$t('sucesso'), result.message);
                            this.$router.push({ path: "/keyAccount/keyAccountList" });
                        }
                        else {
                            this.showToast("error", this.$t('aviso'), result.message);
                        }

                        this.loading = false;
                        this.hideLoading();
                    }
                }
            }
        },

        created() {
            this.showLoading();

            this.getLists();
            this.getRegister();

            this.hideLoading();
        }
    })
</script>

<style scoped>
    .colButtonList {
        padding-top: 20px;    
    }

    .colButtonList2 {
        margin-top: 8px;
    }

    @media only screen and (max-width: 647px) {
        .colButtonList {
            margin-top: -30px;
            display: flex;
            align-items: center;
        }

        .colButtonList2 {
             margin-top: -8px;
        }

        .btnActions {
            margin-bottom: 5px;
        }
    }
</style>